import Form from './Form'
import Contact from './Contact'
import Address from './Address'
import Copyright from './Copyright'
import Container from '../Container'

import { FooterWrapper, FooterContainer, Title } from './styled'
import { Box } from '@mui/material'

export default function Footer() {
  return (
    <Box component="div" width="100%" bgcolor={(theme) => theme.palette.surface['A900']}>
      <Container>
        <FooterWrapper>
          <FooterContainer>
            <Title component="h2">Let&apos;s make your idea happen!</Title>

            <Form />
            <Contact />
            <Address />
            <Copyright />
          </FooterContainer>
        </FooterWrapper>
      </Container>
    </Box>
  )
}
