import { styled, Box } from '@mui/material'
import { IMobileNav } from './types'

export const MobileNav = styled(Box)<IMobileNav>(({ leftposition = 0 }) => ({
  top: '5rem',
  left: leftposition,
  height: '100vh',
  width: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  backdropFilter: 'blur(7.5px)',
  position: 'fixed',
  zIndex: 3,
  paddingLeft: '2.5rem',
  paddingTop: '5rem',
  msTransitionProperty: 'left',
  transitionDuration: '1000ms',
  display: 'initial',
}))
