function MastodonIcon({ color = '#AFBCC4' }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10598_9895)">
        <path
          d="M11.19 12.195C13.206 11.955 14.96 10.72 15.18 9.592C15.528 7.814 15.5 5.253 15.5 5.253C15.5 1.783 13.214 0.765 13.214 0.765C12.062 0.238 10.083 0.017 8.02704 0H7.97704C5.92004 0.017 3.94204 0.238 2.79004 0.765C2.79004 0.765 0.50504 1.782 0.50504 5.253L0.50304 5.915C0.49904 6.555 0.49604 7.265 0.51404 8.006C0.59704 11.4 1.14004 14.746 4.29404 15.576C5.74804 15.959 6.99704 16.039 8.00304 15.984C9.82604 15.884 10.85 15.337 10.85 15.337L10.79 14.02C10.79 14.02 9.48704 14.43 8.02304 14.38C6.57304 14.33 5.04304 14.224 4.80804 12.452C4.78567 12.2876 4.77464 12.1219 4.77504 11.956C4.77504 11.956 6.19904 12.302 8.00304 12.384C9.10604 12.434 10.14 12.32 11.191 12.195H11.19ZM12.803 9.725H11.13V5.645C11.13 4.786 10.766 4.35 10.039 4.35C9.23504 4.35 8.83204 4.867 8.83204 5.891V8.124H7.16804V5.89C7.16804 4.866 6.76504 4.349 5.96104 4.349C5.23404 4.349 4.87004 4.785 4.87004 5.645V9.724H3.19704V5.522C3.19704 4.663 3.41704 3.981 3.85704 3.476C4.31304 2.971 4.90904 2.712 5.65004 2.712C6.50604 2.712 7.15404 3.04 7.58304 3.695L8.00004 4.39L8.41704 3.695C8.84604 3.04 9.49404 2.712 10.351 2.712C11.091 2.712 11.687 2.971 12.142 3.476C12.584 3.981 12.803 4.663 12.803 5.522V9.725Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_10598_9895">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MastodonIcon
