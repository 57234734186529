import { Box, Typography } from '@mui/material'
import { Link } from '../styled'

import { TSLAddressLink } from './constants'

export default function Address() {
  return (
    <Box component="div" sx={{ gridRowStart: 2 }}>
      <Typography
        variant="h6"
        component="p"
        color="surface.100"
        sx={(theme) => ({ mb: theme.spacing(2) })}
      >
        Our office
      </Typography>
      <Link href={TSLAddressLink} target="_blank">
        <Typography variant="subtitle1" component="p" color="surface.100">
          6413 Congress Ave Suite 130
          <br />
          Boca Raton, FL 33487
          <br />
          United States
        </Typography>
      </Link>
    </Box>
  )
}
