import { Box, styled } from '@mui/material'

export const MessageContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1.5fr 1fr',
}))

export const TitleContainer = styled(Box)(({ theme }) => ({
  gridColumnStart: 1,
  gridColumnEnd: 3,
  marginBottom: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    gridColumnEnd: 1,
  },
}))

export const ImageContainer = styled(Box)(({ theme }) => ({
  width: '116px', // image dimensions
  height: '124px', // image dimensions
  position: 'relative',
  alignSelf: 'flex-end',
  justifySelf: 'flex-end',
  [theme.breakpoints.up('sm')]: {
    width: '206px', // image dimensions
    height: '218px', // image dimensions
    gridRowStart: 1,
    gridRowEnd: 3,
    gridColumnStart: 2,
    marginRight: theme.spacing(-6),
    marginLeft: theme.spacing(4),
  },
  [theme.breakpoints.up('md')]: {
    marginRight: 'initial',
    marginLeft: 'initial',
    alignSelf: 'center',
    justifySelf: 'center',
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: theme.spacing(4),
  },
}))
