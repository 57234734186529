import { createBreakpoints } from '@mui/system'

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1440,
  },
})

export default breakpoints
