import { Box, styled, Typography } from '@mui/material'

export const CopyrightContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: theme.spacing(4),
  [theme.breakpoints.up('lg')]: {
    gridColumnStart: 2,
    gridColumnEnd: 4,
    gridRowStart: 4,
    justifySelf: 'flex-end',
    flexDirection: 'row',
    alignSelf: 'flex-end',
    margin: 0,
    marginTop: theme.spacing(3),
  },
}))

export const PipeContainer = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.surface[300],
  display: 'block',
}))

export const FooterLink = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.surface['300'],
  textDecoration: 'none',
  cursor: 'pointer',
  fontWeight: 'bold',
}))
