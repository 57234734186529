import { GridProps } from '@mui/material'

import MastodonIcon from 'components/Icons/MastodonIcon'
import TwitterIcon from 'components/Icons/TwitterIcon'

import { Link, ActionsContainer } from './styled'
import GithubIcon from 'components/Icons/GithubIcon'
import LinkedInIcon from 'components/Icons/LinkedInIcon'
import InstagramIcon from 'components/Icons/InstagramIcon'

export default function SocialMedia(props: GridProps) {
  return (
    <ActionsContainer {...props}>
      <Link
        href="https://github.com/silverlogic"
        target="_blank"
        aria-label="Github"
        title="Github"
      >
        <GithubIcon />
      </Link>
      <Link
        href="https://www.linkedin.com/company/silverlogic-llc"
        target="_blank"
        aria-label="LinkedIn"
        title="LinkedIn"
      >
        <LinkedInIcon />
      </Link>
      <Link href="https://twitter.com/thesilverlogic" target="_blank" aria-label="X" title="X">
        <TwitterIcon />
      </Link>
      <Link
        href="https://www.instagram.com/thesilverlogic"
        target="_blank"
        aria-label="Instagram"
        title="Instagram"
      >
        <InstagramIcon />
      </Link>
      <Link
        href="https://fosstodon.org/@thesilverlogic"
        target="_blank"
        aria-label="Mastodon"
        title="Mastodon"
      >
        <MastodonIcon />
      </Link>
    </ActionsContainer>
  )
}
