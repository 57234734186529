import { styled, Button as MUIButton, Palette } from '@mui/material'
import { preventForwardProp } from 'utils/styledUtils'
import { IButtonProps } from './types'

const bgColors = {
  light: (palette: Palette) => palette.surface['100'],
  dark: (palette: Palette) => palette.surface['800'],
  cta1: (palette: Palette) => palette.primary['500'],
  cta2: (palette: Palette) => palette.primary['600'],
  outlined: () => 'transparent',
}

const fontColors = {
  light: (palette: Palette) => palette.surface['900'],
  dark: (palette: Palette) => palette.surface['100'],
  cta1: (palette: Palette) => palette.primary['50'],
  cta2: (palette: Palette) => palette.primary['50'],
  outlined: (palette: Palette) => palette.primary['50'],
}

export const StyledButton = styled(
  MUIButton,
  preventForwardProp('hideicon'),
)<IButtonProps>(({ theme, buttonvariant = 'cta1' }) => ({
  ...theme.typography.button,
  textTransform: 'none',
  backgroundColor: bgColors[buttonvariant](theme.palette),
  color: fontColors[buttonvariant](theme.palette),
  width: 'max-content',
  padding: theme.spacing(1.25, 2.5),
  borderRadius: '100px',
  boxShadow: 'none',
  border: buttonvariant === 'outlined' ? `1px solid ${theme.palette.surface['100']}` : 'none',
  '&:hover': {
    backgroundColor: bgColors[buttonvariant](theme.palette),
    filter: 'brightness(1.1)',
    boxShadow: 'none',
  },
}))
