import { styled, Box } from '@mui/material'

export const FormWrapper = styled(Box)<IFormProps>(({ theme, hassubmitted }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    gridTemplateRows: hassubmitted ? 'auto auto 1fr' : 'unset',
    paddingRight: theme.spacing(7),
    gridColumnStart: 1,
    gridRowStart: 2,
    gridRowEnd: 5,
  },
}))
