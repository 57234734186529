import { Box, styled } from '@mui/material'

export const ContactContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  margin: theme.spacing(8, 0),
  gap: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    margin: 0,
    alignSelf: 'flex-start',
    gridRowStart: 2,
  },
}))
