import { useRouter } from 'next/router'
import { SnapScroll } from './styled'

const AllowedPages = ['/']

export const AllowedPagesSnapScroll = ({ children }: { children: JSX.Element }) => {
  const router = useRouter()

  if (AllowedPages.includes(router.pathname)) {
    return <SnapScroll>{children}</SnapScroll>
  }

  return <>{children}</>
}
