import { ChangeEvent, useRef, useState } from 'react'
import { StandardTextFieldProps } from '@mui/material'
import FormattableInput from 'components/FormattableInput'
import { PatternFormat } from 'components/FormattableInput/types'

const US_PHONE_MASK = '+# (###) ###-####'
const GLOBAL_PHONE_MASK = '+####################'

type PhoneNumberFieldProps = StandardTextFieldProps &
  PatternFormat & {
    handleOnChange: (e: ChangeEvent<HTMLInputElement>) => void
  }

export default function PhoneNumberField({ handleOnChange, ...props }: PhoneNumberFieldProps) {
  const [phoneMask, setPhoneMask] = useState(US_PHONE_MASK)
  const inputRef = useRef(null)

  return (
    <FormattableInput
      inputRef={inputRef}
      format={phoneMask}
      mask={phoneMask === US_PHONE_MASK ? '_' : ''}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.startsWith('+1') && phoneMask !== US_PHONE_MASK) {
          setPhoneMask(US_PHONE_MASK)
        } else if (!e.target.value.startsWith('+1') && phoneMask !== GLOBAL_PHONE_MASK) {
          setPhoneMask(GLOBAL_PHONE_MASK)
          e.target.value = e.target.value.replace(/\D+$/g, '')
        }
        handleOnChange(e)
      }}
      {...(phoneMask !== US_PHONE_MASK
        ? {
            removeFormatting: (value: string) => value.replace(/[a-zA-Z+]/g, ''),
            getCaretBoundary: (value: string) =>
              Array(value.length + 1)
                .fill(0)
                .map(() => true),
          }
        : {})}
      {...props}
    />
  )
}
