import { styled, Box, Typography, Link as MUILink } from '@mui/material'

export const FooterWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.surface['A900'],
  paddingBottom: theme.spacing(8),
  display: 'flex',
  justifyContent: 'center',
}))

export const FooterContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    display: 'grid',
    gridTemplateColumns: '50% 1.5fr 1fr',
    gridTemplateRows: 'auto 1fr 1fr auto',
  },
}))

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.surface['50'],
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    ...theme.typography.h3,
  },
  [theme.breakpoints.up('lg')]: {
    ...theme.typography.h2,
    gridColumnStart: 1,
    gridColumnEnd: 4,
    marginBottom: theme.spacing(12),
  },
})) as typeof Typography // fixing error when using component="h2"

export const Link = styled(MUILink)({
  textDecorationColor: 'transparent',
})
