import { Container as MuiContainer, ContainerProps, styled } from '@mui/material'

const StyledContainer = styled(MuiContainer)(({ theme }) => ({
  padding: theme.spacing(0, 3),
  maxWidth: '1440px',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 5),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 8),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 10),
  },
  [theme.breakpoints.up('xl')]: {
    padding: 0,
  },
}))

export default function Container({ children, ...props }: ContainerProps) {
  return <StyledContainer {...props}>{children}</StyledContainer>
}
