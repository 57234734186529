import { Components } from '@mui/material/styles'
import breakpoints from './breakpoints'

export const components: Components = {
  MuiButton: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        scrollSnapType: 'y proximity',
        [breakpoints.up('lg')]: {
          scrollSnapType: 'y mandatory',
        },
      },
      body: {
        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
          backgroundColor: '#000000',
          height: '1rem',
        },
        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
          borderRadius: 8,
          backgroundColor: '#009CD8',
          border: '4px solid #000000',
        },
        '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
          backgroundColor: '#19ABE4',
        },
        '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
          backgroundColor: '#19ABE4',
        },
        '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#19ABE4',
        },
        '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
          backgroundColor: '#000000',
        },
        '&::-webkit-scrollbar-button': {
          display: 'none',
        },
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      color: '#fff',
    },
  },
}

