import { styled, keyframes, Box } from '@mui/material'
import { IHamburgerBar, INavLink, IPosition } from './types'

const firstLineAnimation = keyframes`
0% {
    top: 0;
    transform: rotate(0);
}
40% {
    top: 5px;
    transform: rotate(0);
}
100% {
    top: 5px;
    transform: rotate(45deg);
}
`

const firstLineReverseAnimation = keyframes`
0% {
    top: 5px;
    transform: rotate(45deg);
}
40% {
    top: 5px;
    transform: rotate(0);
}
100% {
    top: 0;
    transform: rotate(0);
}
`

const secondLineAnimation = keyframes`
0% {
    transform: scale(1, 1);
}
40% {
    transform: scale(0.5, 1);
}
100% {
    transform: scale(0, 1);
}
`

const secondLineReverseAnimation = keyframes`
0% {
    transform: scale(0, 1);
}
40% {
    transform: scale(0, 1);
}
100% {
    transform: scale(1, 1);
}
`

const thirdLineAnimation = keyframes`
0% {
    bottom: 0px;
    transform: rotate(0);
}
40% {
    bottom: 5px;
    transform: rotate(0);
}
100% {
    bottom: 5px;
    transform: rotate(135deg);
}
`

const thirdLineReverseAnimation = keyframes`
0% {
    bottom: 5px;
    transform: rotate(135deg);
}
40% {
    bottom: 5px;
    transform: rotate(0);
}
100% {
    bottom: 0;
    transform: rotate(0);
}
`

const position: IPosition = {
  first: {
    top: 0,
    bottom: 'auto',
    animation: firstLineAnimation,
    reverseAnimation: firstLineReverseAnimation,
  },
  second: {
    top: '5px',
    bottom: 'auto',
    animation: secondLineAnimation,
    reverseAnimation: secondLineReverseAnimation,
  },
  third: {
    top: 'auto',
    bottom: 0,
    animation: thirdLineAnimation,
    reverseAnimation: thirdLineReverseAnimation,
  },
}

export const HamburgerBar = styled(Box)<IHamburgerBar>(
  ({ theme, order = 'first', open = false }) => ({
    top: position[order].top,
    bottom: position[order].bottom,
    position: 'absolute',
    width: '100%',
    height: '2px',
    background: theme.palette.surface['50'],
    borderRadius: '3px',
    boxShadow: '0 1px 10px #0005',
    animation: open
      ? `${position[order].animation} 0.6s ease-out forwards`
      : `${position[order].reverseAnimation} 0.6s ease-out forwards`,
  }),
)

export const HamburgerIconContainer = styled(Box)(() => ({
  position: 'relative',
}))

export const NavContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
}))

export const NavLink = styled('a', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<INavLink>(({ theme, isActive }) => ({
  ...theme.typography.button,
  textTransform: 'none',
  textDecoration: 'none !important',
  padding: theme.spacing(1.25, 2.5),
  color: 'white',
  position: 'relative',
  '&::after': {
    content: isActive ? '""' : 'none',
    position: 'absolute',
    bottom: 0,
    left: '20px',
    width: 'calc(100% - 40px)',
    height: '2px',
    backgroundColor: theme.palette.primary[500],
    borderRadius: '2px',
  },
  '&:hover::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '20px',
    width: 'calc(100% - 40px)',
    height: '2px',
    backgroundColor: theme.palette.primary[500],
    borderRadius: '2px',
  },
}))
