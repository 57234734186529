import { StandardTextFieldProps, styled, TextField } from '@mui/material'

const StyledInput = styled(TextField)<StandardTextFieldProps>(({ theme, error }) => ({
  '& .MuiInputBase-input': {
    ...theme.typography.body2,
    color: theme.palette.surface['100'],
  },
  '& .MuiFormLabel-root': {
    ...theme.typography.body2,
    color: error ? theme.palette.error.main : theme.palette.surface['500'],
  },
  '& .MuiFormLabel-root.Mui-focused': {
    ...theme.typography.body2,
    color: error ? theme.palette.error.main : theme.palette.primary['500'],
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: theme.spacing(1.5),
      borderColor: theme.palette.surface['600'],
    },
    '&:hover fieldset': {
      borderColor: error ? theme.palette.error.main : theme.palette.surface['600'],
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? theme.palette.error.main : theme.palette.primary['500'],
    },
  },
}))

export default StyledInput
