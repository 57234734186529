import Link from 'next/link'
import { Theme, Typography, useMediaQuery } from '@mui/material'
import { CopyrightContainer, FooterLink, PipeContainer } from './styled'

export default function Copyright() {
  const upLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))
  const currentYear = new Date().getFullYear()

  return (
    <CopyrightContainer>
      <Link passHref href="/privacy-policy">
        <a title="Privacy Policy" style={{ textDecoration: 'none' }}>
          <FooterLink>Privacy Policy</FooterLink>
        </a>
      </Link>
      {upLg && <PipeContainer>|</PipeContainer>}
      <Link passHref href="/sitemap">
        <a title="Sitemap" style={{ textDecoration: 'none' }}>
          <FooterLink>Sitemap</FooterLink>
        </a>
      </Link>
      <Typography variant="caption" color="surface.500">
        © {currentYear} The SilverLogic. All Rights Reserved.
      </Typography>
    </CopyrightContainer>
  )
}
