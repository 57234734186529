export const preventForwardProp = (prop: string) => ({
  shouldForwardProp: (currentProp: string) => currentProp !== prop,
})

export const preventForwardProps = (props: string | string[]) => {
  const propsArray = Array.isArray(props) ? props : [props]

  return {
    shouldForwardProp: (currentProp: string) => !propsArray.includes(currentProp),
  }
}
