import { object, string } from 'yup'

export const validateEmail = (email: string) => {
  const schema = object().shape({
    email: string()
      .email()
      .matches(/@[^.]*\./),
  })

  return schema.isValid({
    email,
  })
}

export const validateRequiredText = (requiredText: string) => {
  const schema = object().shape({
    requiredText: string()
      .trim()
      .min(1)
      .required()
      .matches(/[a-zA-Z]/),
  })
  return schema.isValid({
    requiredText,
  })
}

export const validatePhoneText = (phone: string) => {
  let rule
  if (phone.trim() === '') {
    rule = string()
  } else if (phone.startsWith('+1')) {
    rule = string()
      .matches(/^\+1 \(\d{3}\) \d{3}-\d{4}$/)
      .min(8)
      .max(18)
  } else {
    rule = string().matches(/^\d+$/).min(8).max(16)
    phone = phone.replace(/[^0-9]/g, '')
  }
  const schema = object().shape({
    phone: rule,
  })
  return schema.isValid({
    phone,
  })
}
