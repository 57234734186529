import { Box, Typography } from '@mui/material'
import Image from 'next/image'

import Button from 'components/Button'
import { TitleContainer, ImageContainer, MessageContainer } from './styled'

import { scrollToTop } from 'utils/pageUtils'

import contactImage from '/public/assets/footer/contact_image.webp'

export default function SubmittedFormMessage() {
  return (
    <MessageContainer>
      <TitleContainer>
        <Typography variant="h4" color="surface.100">
          Thanks for getting in touch!
        </Typography>
      </TitleContainer>
      <Box component="div" display="flex" flexDirection="column" gap="24px">
        <Typography variant="subtitle2" color="surface.100">
          You&apos;ll hear from us soon! Please keep an eye on your email and/or your phone.
        </Typography>
        <Button
          buttonvariant="light"
          iconprops={{ style: { transform: 'rotate(-90deg)' } }}
          sx={{ alignSelf: 'flex-start' }}
          onClick={scrollToTop}
        >
          Go up
        </Button>
      </Box>
      <ImageContainer>
        <Image src={contactImage} alt="Contact mail" layout="fill" />
      </ImageContainer>
    </MessageContainer>
  )
}
