import { styled, Box } from '@mui/material'

export const SnapScroll = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    scrollSnapAlign: 'start',
    height: 'calc(100vh - 80px)',
    scrollMarginTop: '80px',
    marginBottom: '80px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}))
