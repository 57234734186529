import { ChangeEvent, FormEvent, useState } from 'react'
import Script from 'next/script'

import { Typography } from '@mui/material'

import Input from 'components/Input'
import Button from 'components/Button'
import SubmittedFormMessage from './SubmittedFormMessage'
import { FormWrapper } from './styled'

import { validateEmail, validatePhoneText, validateRequiredText } from 'utils/formUtils'
import { contactFormId } from 'shared/constants'
import PhoneNumberField from './PhoneNumberField'

const initialFormState = {
  name: '',
  email: '',
  phoneNumber: '+1',
  message: '',
}

export default function Form() {
  const [formState, setFormState] = useState(initialFormState)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [nameError, setNameError] = useState('')
  const [messageError, setmessageError] = useState('')
  const [phoneNumberError, setPhoneNumberError] = useState('')
  const [showPhoneNumber, setShowPhoneNumber] = useState(false)

  const handleSubmit = async (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault()

    if (
      (await handleInputValidation('email')) &&
      (await handleInputValidation('name')) &&
      (await handleInputValidation('message')) &&
      (await handleInputValidation('phoneNumber'))
    ) {
      setHasSubmitted(true)
    }
  }

  const handleInputValidation = async (inputType: string) => {
    switch (inputType) {
      case 'email':
        const isEmailValid = await validateEmail(formState.email)
        if (!isEmailValid) {
          setEmailError('Please enter a valid email')
          return false
        }
        setEmailError('')
        break
      case 'name':
        const isNameValid = await validateRequiredText(formState.name)
        if (!isNameValid) {
          setNameError('Please enter a valid name')
          return false
        }
        setNameError('')
        break
      case 'message':
        const ismessageValid = await validateRequiredText(formState.message)
        if (!ismessageValid) {
          setmessageError('Please enter a valid message')
          return false
        }
        setmessageError('')
        break
      case 'phoneNumber':
        const phoneNumber = formState.phoneNumber
        // The following if makes sure the empty phone number pass as valid.
        if (phoneNumber.startsWith('+1') && phoneNumber.replace(/[^0-9]/g, '') === '1') {
          setPhoneNumberError('')
          break
        }
        const isPhoneNumberValid = await validatePhoneText(phoneNumber)
        if (!isPhoneNumberValid) {
          setPhoneNumberError('Please enter a valid phone number')
          return false
        }
        setPhoneNumberError('')
        break
    }
    return true
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e

    if (target.name === 'email' && emailError) {
      setEmailError('')
    } else if (target.name === 'name' && nameError) {
      setNameError('')
    } else if (target.name === 'message' && messageError) {
      setmessageError('')
    } else if (target.name === 'phoneNumber' && phoneNumberError) {
      setPhoneNumberError('')
    }

    setFormState((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }))
  }

  return (
    <FormWrapper
      component="form"
      id={contactFormId}
      hassubmitted={hasSubmitted ? 'true' : 'false'} // preventing warning
      onSubmit={handleSubmit}
    >
      <Script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/3838537.js"
      />

      {hasSubmitted ? (
        <SubmittedFormMessage />
      ) : (
        <>
          <Typography variant="h6" component="p">
            Get in touch
          </Typography>
          <Input
            required
            placeholder="Your name"
            label="Your name"
            autoComplete="off"
            name="name"
            onChange={handleInputChange}
            onBlur={(e) => handleInputValidation(e.target.name)}
            value={formState.name}
            sx={(theme) => ({ marginTop: theme.spacing(1) })}
            error={Boolean(nameError)}
            helperText={nameError}
          />
          <Input
            required
            label="Your e-mail address"
            placeholder="Your e-mail address"
            autoComplete="off"
            name="email"
            onChange={handleInputChange}
            onBlur={(e) => handleInputValidation(e.target.name)}
            value={formState.email}
            error={Boolean(emailError)}
            helperText={emailError}
          />
          <PhoneNumberField
            label="Phone number (optional)"
            placeholder="Phone number (optional)"
            autoComplete="off"
            name="phoneNumber"
            handleOnChange={handleInputChange}
            onBlur={(e) => handleInputValidation(e.target.name)}
            onFocus={(e) => {
              setShowPhoneNumber(true)
              // Fixes the mask UX, as it gets lost when a value is loaded after the mask.
              setTimeout(() => {
                e.target.setSelectionRange(4, 4)
              }, 3)
            }}
            value={showPhoneNumber ? formState.phoneNumber : ''}
            error={Boolean(phoneNumberError)}
            helperText={phoneNumberError}
          />
          <Input
            label="Tell us about your project"
            multiline
            required
            placeholder="Tell us about your project"
            rows={4}
            autoComplete="off"
            name="message"
            onChange={handleInputChange}
            onBlur={(e) => handleInputValidation(e.target.name)}
            value={formState.message}
            error={Boolean(messageError)}
            helperText={messageError}
          />

          <Button type="submit" buttonvariant="light" sx={{ justifySelf: 'flex-end' }}>
            Send
          </Button>
        </>
      )}
    </FormWrapper>
  )
}
