export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

export const scrollToElement = (id: string, options?: ScrollIntoViewOptions, changeUrl = true) => {
  const element = document.querySelector(id)

  if (!element) return
  element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest', ...options })

  if (!changeUrl) return
  const newUrl = `${window.location.href.split('#')[0]}${id}`
  history.pushState(null, '', newUrl)
}

export const getFullWidth = () => {
  const windowInnerWidth = typeof window !== 'undefined' ? window.innerWidth : 0
  const documentClientWidth =
    typeof document !== 'undefined'
      ? document.body.clientWidth || document.documentElement.clientWidth
      : 0

  if (!windowInnerWidth || !documentClientWidth) return '100vw'

  return `calc(100vw - ${windowInnerWidth - documentClientWidth}px)`
}

export const getWindowHeight = () => {
  return (
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0
  )
}

export const getWindowYScroll = () => {
  return window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop || 0
}

export const getDocumentHeight = () => {
  return Math.max(
    document.body.scrollHeight || 0,
    document.documentElement.scrollHeight || 0,
    document.body.offsetHeight || 0,
    document.documentElement.offsetHeight || 0,
    document.body.clientHeight || 0,
    document.documentElement.clientHeight || 0,
  )
}

export const getScrollPercentage = () => {
  return ((getWindowYScroll() + getWindowHeight()) / getDocumentHeight()) * 100
}
