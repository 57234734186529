function InstagramIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10598_9894)">
        <path
          d="M15.9679 4.49451C15.9679 6.79505 15.9679 9.09559 15.9679 11.3961C15.9303 11.7001 15.9052 12.0042 15.8551 12.3082C15.5416 14.1167 14.2691 15.4393 12.4826 15.8123C11.6928 15.9753 10.8936 15.9878 10.0912 15.9941C8.69645 16.0035 7.30171 16.0003 5.90697 15.9941C5.22057 15.9909 4.53417 15.969 3.85404 15.875C1.97349 15.6148 0.597554 14.3517 0.190102 12.5025C0.0208528 11.7409 0.0114493 10.9636 0.0051808 10.1894C-0.00422194 9.02977 0.00204732 7.8701 0.00204732 6.71042C0.00204732 5.81716 -0.00735695 4.92077 0.102342 4.03064C0.202638 3.2126 0.450242 2.44785 0.951722 1.78025C1.80424 0.645655 2.98899 0.162981 4.35865 0.0532822C4.6282 0.0313425 4.89774 0.0188055 5.16729 0C7.05097 0 8.93465 0 10.8183 0C10.881 0.0062685 10.9406 0.0188055 11.0033 0.0188055C11.4577 0.025074 11.9091 0.0595507 12.351 0.14731C14.1124 0.504614 15.2721 1.52011 15.7673 3.26589C15.877 3.6702 15.9209 4.08392 15.9679 4.49451ZM8.00378 14.5335C8.93465 14.5492 9.86553 14.5178 10.7964 14.499C11.2383 14.4896 11.6803 14.4708 12.1159 14.3799C13.2725 14.1386 14.0309 13.471 14.3412 12.3207C14.4885 11.7785 14.5073 11.2206 14.5136 10.6658C14.5324 9.35886 14.5324 8.05502 14.5293 6.74804C14.5262 5.85791 14.545 4.96778 14.4259 4.08392C14.2472 2.76441 13.4417 1.89622 12.1473 1.62354C11.5204 1.4919 10.8842 1.48877 10.251 1.4825C8.75914 1.46683 7.2641 1.46683 5.7722 1.4825C5.17669 1.48877 4.57805 1.49817 3.98254 1.59847C2.73825 1.80846 1.91707 2.58262 1.63812 3.81751C1.51275 4.36914 1.50022 4.93331 1.49395 5.49434C1.47828 7.16489 1.48141 8.83544 1.49395 10.506C1.49708 11.0231 1.51276 11.5466 1.61305 12.0606C1.84185 13.2453 2.50944 14.0258 3.69106 14.3392C4.19254 14.4708 4.70969 14.4928 5.2237 14.5053C6.15144 14.521 7.07604 14.5492 8.00378 14.5335Z"
          fill="#AFBCC4"
        />
        <path
          d="M7.99438 12.1232C5.72832 12.1201 3.87598 10.2583 3.87598 7.98912C3.87911 5.72306 5.74086 3.86445 8.00692 3.86759C10.2792 3.86759 12.141 5.73873 12.1347 8.01106C12.1253 10.274 10.2636 12.1263 7.99438 12.1232ZM7.99751 10.6376C9.46121 10.6376 10.6491 9.46222 10.6491 8.00793C10.6522 6.54737 9.47061 5.35322 8.01945 5.35009C6.54635 5.34695 5.36161 6.52543 5.36161 7.98912C5.36161 9.45282 6.53695 10.6344 7.99751 10.6376Z"
          fill="#AFBCC4"
        />
        <path
          d="M12.2691 4.69487C11.7237 4.69487 11.3037 4.27174 11.3037 3.72325C11.3037 3.18416 11.7331 2.7485 12.2691 2.74536C12.7925 2.7391 13.2469 3.19043 13.2532 3.71698C13.2563 4.25607 12.8144 4.69487 12.2691 4.69487Z"
          fill="#AFBCC4"
        />
      </g>
      <defs>
        <clipPath id="clip0_10598_9894">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default InstagramIcon
