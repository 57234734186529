import { FC } from 'react'
import { NumberFormatBase, usePatternFormat } from 'react-number-format'
import { IFormattableInput } from './types'
import Input from 'components/Input'

const FormattableInput: FC<IFormattableInput> = ({ format = '', ...props }) => {
  const { format: _format } = usePatternFormat({ ...props, format: format })

  return <NumberFormatBase {...props} customInput={Input} format={format ? _format : undefined} />
}

export default FormattableInput
