import { SvgIcon, SvgIconProps } from '@mui/material'

function BarsIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3.57097 24.5L15.2153 0.5H12.5808L0.910156 24.5H3.57097Z" fill={props.color} />
        <path d="M13.3559 24.5L25.0002 0.5H22.3658L10.6951 24.5H13.3559Z" fill={props.color} />
      </svg>
    </SvgIcon>
  )
}

export default BarsIcon
