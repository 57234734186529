import { SvgIcon, SvgIconProps } from '@mui/material'

function StarsIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 13 14" {...props}>
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.184 3.551L6.5 1.5L5.816 3.551C5.617 4.148 5.148 4.617 4.551 4.816L2.5 5.5L4.551 6.184C5.148 6.383 5.617 6.852 5.816 7.449L6.5 9.5L7.184 7.449C7.383 6.852 7.852 6.383 8.449 6.184L10.5 5.5L8.449 4.816C7.851 4.617 7.383 4.149 7.184 3.551Z"
          fill="white"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 9.5V12.5"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5 11H8.5"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 9V11"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 10H1"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

export default StarsIcon
