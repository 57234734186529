import { Typography } from '@mui/material'

import { ContactContainer } from './styled'
import { Link } from '../styled'

export default function Contact() {
  return (
    <ContactContainer>
      <Typography variant="h6" component="p" color="surface.100">
        Contact
      </Typography>
      <Link href="tel:+15615692366">
        <Typography component="span" variant="h4" color="surface.100">
          1 (561) 569-2366
        </Typography>
      </Link>
      <Link href="mailto:hello@tsl.io">
        <Typography component="span" variant="h4" color="surface.100">
          hello@tsl.io
        </Typography>
      </Link>
    </ContactContainer>
  )
}
