import React, { ForwardedRef } from 'react'
import { StyledButton } from './styled'
import { IButtonProps } from './types'

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

function Button(buttonProps: IButtonProps, ref: ForwardedRef<HTMLButtonElement>) {
  const getStartIcon = () => {
    if (buttonProps.showicon) return <KeyboardArrowRightIcon {...buttonProps.iconprops} />
    if (buttonProps.hideicon) return null
    switch (buttonProps.buttonvariant) {
      case 'light':
      case 'dark':
        return <KeyboardArrowRightIcon {...buttonProps.iconprops} />
      default:
        return null
    }
  }

  return (
    <StyledButton
      ref={ref}
      startIcon={getStartIcon()}
      variant={buttonProps.variant === 'outlined' ? 'outlined' : 'contained'}
      {...buttonProps}
    />
  )
}

export default React.forwardRef(Button)
